@font-face {
  font-family: MontserratRegular;
  src: url("../Fonts/woff2Format/Montserrat-Regular.woff2") format("woff2");
}

@font-face {
  font-family: SofiaPro-Bold;
  src: url("../Fonts/woff2Format/SofiaPro-Bold.woff2") format("woff2");
}
@font-face {
  font-family: SofiaPro-Regular;
  src: url("../Fonts/woff2Format/Sofia-Pro-Regular.woff2") format("woff2");
}
@font-face {
  font-family: SofiaPro-SemiBold;
  src: url("../Fonts/woff2Format/SofiaPro-SemiBold.woff2") format("woff2");
}
// @font-face {
//   font-family: Fahkwang;
//   src: url("./components/fonts/Fahkwang.ttf");
// }

// @font-face {
//   font-family: FahkwangRegular;
//   src: url("./components/fonts/Fahkwang-Regular.ttf");
// }

// @font-face {
//   font-family: LibreBaskerville;
//   src: url("./components/fonts/LibreBaskerville-Regular.ttf");
// }

// @font-face {
//   font-family: GothamXLight;
//   src: url("./components/fonts/Gotham-XLight.otf");
// }

// @font-face {
//   font-family: GothamBook;
//   src: url("./components/fonts/GothamBook.ttf");
// }

// @font-face {
//   font-family: GothamLight;
//   src: url("./components/fonts/Gotham-Light.ttf");
// }

// @font-face {
//   font-family: LibreBaskervilleRegular;
//   src: url("./components/fonts/LibreBaskerville-Regular.ttf");
// }

// @font-face {
//   font-family: GothamMedium;
//   src: url("./components/fonts/GothamMedium.ttf");
// }

// @font-face {
//   font-family: FahkwangSemiBold;
//   src: url("./components/fonts/Fahkwang-SemiBold.ttf");
// }

// @font-face {
//   font-family: RobotoRegular;
//   src: url("./components/fonts/Roboto-Regular.ttf");
// }
