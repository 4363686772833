@import "./font";
@import "./color";
@import "./mixins";
$desk: 100/1400;
$mul: 100/800;
:root {
  --br-blobby: 50%;
}
body {
  overflow-x: hidden;
  margin: 0 !important;
  width: 100%;
  height: auto;
}
img {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
}
.cursor {
  position: fixed;
  background-color: transparent;
  width: 25 * $desk + vw;
  height: 25 * $desk + vw;
  border: 2px solid #000;
  border-radius: 95% 89% 78% 71% / 91% 70% 84%;
  z-index: 10000;
  transition: 0.5s, top 0s, left 0s !important;
  animation: cursorAnim 0.5s infinite alternate;
  pointer-events: none;
  //transition: all 1s;
}
.link-animate {
  width: 50 * $desk + vw;
  height: 50 * $desk + vw;
  border: 2px solid #fff;
  background-color: #fff;
  mix-blend-mode: exclusion;
}
input,
textarea {
  cursor: pointer;
}
// .link-animate::after {
//   content: "";
//   width: 100 * $desk + vw;
//   height: 100 * $desk + vw;
//   position: absolute;
//   border: 8 * $desk + vw solid gray;
//   border-radius: 50%;
//   opacity: 0.9;
//   top: -25 * $desk + vw;
//   left: -25 * $desk + vw;
//   animation: cursorAnim2 0.5s infinite alternate;
// }
.blob-animation {
  position: relative;
  border-radius: 50%;
  border-radius: var(--br-blobby);
  transition: all 1.5s ease-out;
  
}
textarea {
  resize: vertical;
}

.hidden {
  display: none;
}
.capitalize {
  text-transform: capitalize;
}
.sky-blue {
  color: #00e5ea;
  text-decoration: underline;
}
a {
  text-decoration: none;
  cursor: pointer;
  span {
    cursor: pointer;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}
@keyframes cursorAnim2 {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(0.4);
  }
}
@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-40 * $desk + vw);
    transform: translatey(-40 * $desk + vw);
  }
  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}


@media screen and (max-width: 800px) {
  body {
    overflow-y: scroll;
    margin: 0 !important;
    width: 100%;
    padding-top: 55 * $mul + vw;
    cursor: context-menu;
  }
  a,
  .send-message {
    cursor: pointer;
  }
  input,
  textarea {
    cursor: text;
  }

  .cursor {
    display: none;
  }
  .hide-mobile {
    display: none;
  }
}

